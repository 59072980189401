import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#FFFEFC',
      neutralLight2: '#FAFAFA',
      neutralLight1: '#F5F5F5',
      neutralDark4: '#585858',
      neutralDark3: '#002D34',
      neutralDark2: '#131415',
      neutralDark1: '#000000',
      primaryLight: '#003E48',
      dangerLight: '#F23030',
    },
  },
  fontFamily: {
    heading: "'contralto-big', serif",
    label: "'futura-pt', sans-serif",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark2};
    font-family: ${theme.fontFamily.label};
    font-size: 0.8125rem;
    letter-spacing: 0.2em;
    line-height: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  `,
  labelTitle: css`
    color: ${theme.colors.variants.neutralDark2};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1.5rem;
    line-height: 2.125rem;
    font-weight: 500;

    @media (max-width: 1199px) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  `,
  title: {
    xl: css`
      ${titleStyle}
      font-size: 9.722vw;
      line-height: 9.722vw;
      text-transform: uppercase;

      @media (min-width: 1440px) {
        font-size: 8.625rem;
        line-height: 8.625rem;
      }

      @media (max-width: 1199px) {
        font-size: 4.25rem;
        line-height: 4.25rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 5.25rem;
      line-height: 5.25rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 2.75rem;
        line-height: 2.75rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 3.75rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 2.75rem;
        line-height: 2.75rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3rem;
      text-transform: uppercase;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-size: 1.75rem;
      line-height: 2.3125rem;

      @media (max-width: 1199px) {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
    `,
  },
  description: {
    medium: css`
      color: ${theme.colors.variants.neutralDark4};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 300;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    `,
    small: css`
      color: ${theme.colors.variants.neutralDark4};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.5rem;
    `,
  },
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
